<template>
    <div class="box">
        <e-header :step="2" @next="submitForm" :showbtn="showbtn" />
        <div class="content">
            <div class="item">
                <div class="title">
                    <div class="title_l">
                        <i class="solid"></i>
                        <p>强制执行排查</p>
                    </div>
                    <div class="title_r" v-show="!qiangzhishow">
                        <el-button type="primary" size="mini" @click="qiangzhiRightClick">查看</el-button>
                    </div>
                    <!-- <div class="title_r" v-show="!qiangzhishow">{{ qiangzhi.CBK[0] ? qiangzhi.CBK[0].advice : '此地暂无数据'
                        }}
                    </div> -->
                </div>
                <qiangzhi @callback="qiangzhiCBK" v-show="qiangzhishow" />
            </div>
            <div class="item" style="margin-top: 16px;">
                <div class="title">
                    <div class="title_l">
                        <i class="solid"></i>
                        <p>风险排查</p>
                        <span class="esc" v-show="!fxpcshow">曝光台检索、重复签约检索
                            <span v-show="fxpcdata.content">、三要素报告</span>
                        </span>
                    </div>
                    <div class="title_r">
                        <div class="title_r" v-show="!fxpcshow"> <el-button type="primary" size="mini"
                                @click="fxpcRightClick">查看</el-button></div>
                    </div>

                </div>
                <InspectSearch ismode @success="fxpcchange" v-show="fxpcshow" />
            </div>
            <div class="item" style="margin-top: 16px;">
                <div class="title">
                    <div class="title_l">
                        <i class="solid"></i>
                        <p>合同保</p>
                        <span class="esc" v-show="!hetongbaoshow">{{ $store.state.electronic.electronicData.title
                            }}</span>
                    </div>
                    <div class="title_r" v-show="!hetongbaoshow"> <el-button type="primary" size="mini"
                            @click="hetongbaoRightClick">修改</el-button></div>
                </div>
                <hetongbao ref="hetongbao" @success="hetongbaochange" v-show="hetongbaoshow" />
            </div>

        </div>
        <InspectDetail ref="InspectDetail" />
    </div>
</template>

<script>
import eHeader from "@/views/ElectronicDocuments/components/eHeader.vue"
import qiangzhi from "@/views/ContractInsurance/qiangzhi.vue"
import InspectSearch from "@/views/Inspect/InspectSearch.vue"
import hetongbao from "@/views/ContractInsurance/hetongbao.vue"
import InspectDetail from "@/views/Inspect/components/InspectDetail.vue"
export default {
    components: {
        eHeader,
        qiangzhi,
        hetongbao,
        InspectSearch,
        InspectDetail
    },
    data() {
        return {
            fxpcshow: true,
            hetongbaoshow: true,
            qiangzhishow: true,
            fxpcdata: {
                detectionId: '',
                content: '',
                name: '',
                idNumber: '',
                phone: '',
                createdAt: '',
                exposureList: null,
                contractList: null,
                verification: null,
            },
            qiangzhi: {
                CBK: []
            }
        }
    },
    computed: {
        showbtn() {
            return (!this.fxpcshow) && (!this.hetongbaoshow)
        }
    },
    methods: {
        submitForm() {
            this.$router.push({ name: 'electronicDocumentsPDF' })
        },
        hetongbaochange(res) {
            this.hetongbaoshow = res
        },
        hetongbaoRightClick() {
            this.hetongbaoshow = true;
            this.$refs.hetongbao.clear()
        },
        fxpcchange(res) {
            console.log(res)
            this.fxpcshow = false;
            this.fxpcdata = res;
        },
        fxpcRightClick() {
            this.$refs.InspectDetail.show(this.fxpcdata.detectionId)
        },
        qiangzhiCBK(res) {
            this.qiangzhishow = false;
            this.qiangzhi.CBK = res;
        },
        qiangzhiRightClick() {
            const { qiangzhi } = this
            const message = qiangzhi.CBK[0] ? qiangzhi.CBK[0].advice : '此地暂无数据';
            this.$alert(message, '提示', {
                confirmButtonText: '确定',
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    padding-bottom: 30px;

    .content {
        width: 100%;
        margin: 0 auto;
        margin-top: 16px;

        /deep/.inspiectSearch {
            .title {
                display: none;
            }
        }

        .title {
            height: 60px;
            display: flex;
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            align-items: center;
            padding: 0 20px;
            justify-content: space-between;

            &_l {
                display: flex;
                align-items: center;

                .solid {
                    border-radius: 10px;
                    background: #0062FF;
                    width: 2px;
                    height: 16px;
                    margin-right: 10px;
                }
            }

            &_r {
                color: #999;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

        }

        .esc {
            color: #999;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 10px;
        }

        .item {
            background: #fff;

        }
    }
}
</style>