/*
* @Author: 曹俊杰
* @Date: 2022-05-10 18:47:09
* @Module: 三要素查询 风险排查
*/
<template>
  <div class="inspiectSearch">
    <el-form :model="form" ref="form" :rules="rules">
      <div class="box">
        <div class="title">风险排查</div>
        <div class="row">
          <div class="row-left">选择排查内容：</div>
          <div class="row-right ">
            <el-form-item>
              <verification v-model="form.verification" />
            </el-form-item>
          </div>
        </div>
        <div class="row" style="margin-top: 40px;">
          <div class="row-left" style="line-height: 36px;">填写签约方信息：</div>
          <div class="row-right">
            <div class="form">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item prop="name" label="姓名">
                    <el-input v-model="form.name" placeholder="请输入被查询姓名"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="phone" label="手机号">
                    <el-input v-model="form.phone" placeholder="请输入被查询人手机号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="idNumber" label="身份证号">
                    <el-input v-model="form.idNumber" placeholder="请输入被查询人身份证号 "></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="verifyCode" label="验证码">
                    <el-input v-model="form.verifyCode" placeholder="请输入验证码">
                      <i slot="suffix" style="font-size: 14px;color:#0062FF;margin-right:15px;" @click="getcode">{{
                        secondrender }}</i>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-button type="primary" :disabled="disabled" @click="postDetection" :loading="loading"
                class="button">提交</el-button>
            </div>
          </div>
        </div>

      </div>
    </el-form>


  </div>
</template>
<script>

import verification from "@/components/form/verification.vue"
export default {
  components: { verification },
  props: {
    ismode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    const idNumberValidator = (_, value, callback) => {
      console.log(value)
      if (this.$utils.test.idCardStrict(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的身份证号'));
      }
    }
    return {
      second: 0,
      loading: false,

      form: {
        name: '',
        phone: '',
        idNumber: '',
        verifyCode: '',
        verification: false
      },
      rules: {
        name: [{ required: true, message: '请输入被查询姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入被查询人手机号', trigger: 'blur' }, { validator: phoneValidator, trigger: 'blur' }],
        idNumber: [{ required: true, message: '请输入被查询人身份证号', trigger: 'blur' }, { validator: idNumberValidator, trigger: 'blur' }],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur' }
        ],
      }
    };
  },
  computed: {
    disabled() {
      return this.$utils.test.empty(this.form.name) || this.$utils.test.empty(this.form.phone) || this.$utils.test.empty(this.form.idNumber) || this.$utils.test.empty(this.form.verifyCode);
    },
    secondrender() {
      return this.second == 0 ? '获取验证码' : this.second + 's';
    }
  },
  mounted() { },
  methods: {
    secondrun() {
      let js = setInterval(() => {
        this.second--;
        if (this.second == 0) {
          clearInterval(js);
        }
      }, 1000);
    },
    validateField(arr = []) {
      return new Promise((resolve, reject) => {
        this.$refs.form.validateField(arr, res => {
          resolve(!res)
        })
      })
    },
    async getcode() {
      if (this.second !== 0) {
        return;
      }
      const verify = await this.validateField(['phone']);
      if (verify) {
        const { status, info } = await this.$api.postSmsCodeVerification({ phone: this.form.phone });
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          });
          this.second = 60;
          this.secondrun();
        } else {
          this.$message({
            message: info,
            type: 'error'
          });
        }
      }
    },
    alert() {
      return new Promise((resolve, reject) => {
        this.$alert('<span>三要素报告需要5-10分钟的查询过程，可稍后前往风险排查中的<span style="color:#0062FF;">排查记录</span>查看结果 </span>', '温馨提示', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: 'true',
          showCancelButton: 'true',
          type: 'info',
          callback: action => {
            if (action === 'confirm') {
              resolve()
            } else {
              reject()
            }
          }
        });
      })
    },
    testpostDetection() {
      this.$emit('success', {
        detectionId: '',
        content: '',
        name: '',
        idNumber: '',
        phone: '',
        createdAt: '',
        exposureList: null,
        contractList: null,
        verification: null,
      })
    },
    async postDetection() {
      this.loading = true
      try {
        await this.$refs.form.validate()
        await this.alert()
        const { status, data, info } = await this.$api.postDetection(this.form)
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          if (this.ismode) {
            this.$emit('success', data)
          } else {
            this.$router.push({
              path: '/inspect/inspectResult',
              query: {
                fileUrl: data.fileUrl
              }
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
        this.loading = false;

      } catch (e) {
        console.log('error', e)
        this.loading = false
      }
    },
    noCode() {
      this.$alert('如无法获取验证码，请联系对接法务或客服。', '帮助', {
        confirmButtonText: '确定'
      });
    }
  },
};
</script>
<style lang='scss' scoped>
.inspiectSearch {
  padding: 16px 24px;


  .box {
    background: #fff;
    border-radius: 6px;
    padding-bottom: 40px;

    .title {
      height: 60px;
      line-height: 60px;
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      padding: 0 20px;
    }

    .row {
      display: flex;

      .row-left {
        width: 162px;
        text-align: right;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 14px;
      }



      .row-right {
        flex: 1;
        padding-right: 50px;



      }


    }

    .button {
      width: 110px;
      height: 40px;
    }
  }


  // background: #fff;
  // min-height: calc(100vh - 120px - 28px);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // padding-bottom: 100px;
  // background-image: url(~@/static/image/inspect_bg_1.png);
  // background-repeat: no-repeat;
  // background-size: 100% auto;
  // background-position: top center;
  // .content {
  //   width: 440px;
  //   background: #ffffff;
  //   box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
  //   border-radius: 6px;
  //   border: 1px solid #e3e3e3;
  //   margin-top: 175px;
  //   padding: 30px;
  //   h1 {
  //     text-align: center;
  //     font-size: 22px;
  //     font-family: PingFangSC-Medium, PingFang SC;
  //     font-weight: 500;
  //     color: #262626;
  //     line-height: 30px;
  //   }
  // }
}
</style>