<template>
    <div class="qiangzhipaicha">
        <el-alert title="未来强制执行地：当取得商事仲裁裁决书后，需要去哪个地方的中级人民法院申请强制执行，一般为败诉方的户籍所在地、常居地或财产所在地。" type="info" show-icon>
        </el-alert>
        <el-form :model="form" ref="form" label-position="left" label-width="150px" label-suffix="：" size="mini"
            style="margin-top: 20px;">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item prop="name" label="审理机关">
                        <el-select v-model="form.type" style="width: 30%;" size="mini" placeholder="请选择合审理机关">
                            <el-option v-for="item in enforceType" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-show="form.type === 2">
                    <el-form-item prop="name" label="未来强制执行地">
                        <city v-model="form.cityId" style="width: 30%;" />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="getEnforceAdviceList">提交</el-button>
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>
    </div>
</template>

<script>
import city from '@/components/form/city'
import selectData from '@/mixins/selectData.js'
export default {
    mixins: [selectData],
    components: {
        city
    },
    data() {
        return {
            form: {
                type: null,
                cityId: 0
            },
            rules: [


            ]
        }
    },
    methods: {
        async getEnforceAdviceList() {
            const { status, info, data } = await this.$api.getEnforceAdviceList(this.form)
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.$emit('callback', data)
            } else {
                this.$message({
                    type: 'error',
                    message: info

                })
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.qiangzhipaicha {
    padding: 0 20px;

    /deep/ .el-form-item__label {
        text-align: end;
    }
}
</style>