/*
* @Author: 曹俊杰
* @Date: 2024-09-22 12:01:23
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-09-29 23:24:32
*/
<template>
    <svg v-if="value" class="svgicon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
        fill="none" @click="change(false)">
        <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="#0062FF" />
        <rect x="5" y="5" width="8" height="8" rx="4" fill="#0062FF" />
    </svg>
    <svg v-else class="svgicon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
        fill="none" @click="change(true)">
        <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="white" stroke="#EEEEEE" />
    </svg>
</template>

<script>
export default {
    props: {
        value: {
            style: Boolean,
            default: false,
        }
    },
    methods: {
        change(value) {
            console.log(value)
            this.$emit('input', value)
        },
    }

}
</script>
