<template>
    <div class="hetongbao">
        <insuranceSelect :insurance="insurance" @change="change" />
        <div class="bottom">
            <div class="bottom_1" @click="sub">提交方案</div>
            <div class="bottom_2" @click="fangqi">放弃保障</div>
            <div class="bottom_3" v-if="insurance.title">已选择：
                <span class="primary">{{ insurance.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import insuranceSelect from "@/components/insuranceSelect"
export default {
    components: {
        insuranceSelect
    },
    data() {
        return {
            insurance: {
                insuranceId: null,
                title: '',
                insuranceVersion: null
            }
        }
    },
    methods: {
        clear() {
            const def = {
                insuranceId: null,
                title: '',
                insuranceVersion: null
            }
            this.insurance = def
            this.$store.commit('electronic/setElectronic', def);
        },
        fangqi() {
            this.clear();
            this.$emit('success', false)
        },
        sub() {
            this.$emit('success', true)
            this.$store.commit('electronic/setElectronic', this.insurance);
        },
        change(insurance){
            this.insurance = insurance
        }

    }
}
</script>

<style lang="scss" scoped>
.hetongbao {


    .bottom {
        background: #fff;
        box-shadow: 0px -6px 8px 0px rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: center;
        padding: 0 30px;
        height: 80px;

        &_1 {
            width: 102px;
            height: 38px;
            flex-shrink: 0;
            color: #fff;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 38px;
            text-align: center;
            border-radius: 2px;
            background: #0062FF;
        }

        &_2 {
            color: #999;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0 40px;
        }

        &_3 {
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #666;
        }
    }
}
</style>