<template>
    <div>
        <el-cascader :value="value" :options="list" placeholder="请选择渠道" clearable @change="change" style="width:100%;"
            :props="props" ></el-cascader>
        <!-- <el-select :value="value" placeholder="请选择渠道" clearable @change="change" style="width:100%;">
            <el-option v-for="item in list" :key="item.code" :label="item.name" :value="item.divisionId">
            </el-option>
        </el-select> -->
    </div>
</template>
<script>
import { Promise } from 'core-js';

export default {
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    components: {},
    data() {
        return {
            list: [],
            props: {
                value: 'divisionId',
                label: 'name',
                lazy: true,
                checkStrictly: false,
                lazyLoad: async (node, resolve) => {
                    const nodes = await this.getDivisionList(node.value)
                    resolve(nodes.map(res=>({...res,leaf:node.level >0})));
                }
            }
        };
    },
    mounted() {

    },
    methods: {
        change(value) {
            console.log('value',value)
            this.$emit('input', value.lastItem)
        },
        async getDivisionList(parentId) {
            let params = {};
            parentId && (params.parentId = parentId)
            const { data } = await this.$api.getDivisionList(params);
            return new Promise((resolve, reject) => {
                resolve(data)
            })
        }
    },
};
</script>
<style lang='scss' scoped></style>